import React from 'react';
import { useBranding } from 'hooks/useBranding';

import './index.css';

export const BrandingLabel = ({
  brandingId,
  noBrandingText = 'Ambos',
  width = '100%',
}) => {
  const { getBrandingAcronymById } = useBranding();
  const brandingAcronym = getBrandingAcronymById(brandingId);

  return (
    <button
      className={`branding-label ${brandingAcronym.toLowerCase()}`}
      style={{ width }}
    >
      <span className="flex center">
        {getBrandingAcronymById(brandingId) || noBrandingText}
      </span>
    </button>
  );
};
